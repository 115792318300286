import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@core/store';
import { currentAccesos, loadedAccesos } from '@core/store/selectors/accesos.selectors';
import { KEY_FORM } from '~shared/enums/EnumForm';

@Injectable({
	providedIn: 'root',
})
export class FormsGuard  {
	constructor(private readonly _store: Store<AppState>, private readonly _router: Router) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const formCode = next.data[KEY_FORM];

		return this._store.select(loadedAccesos).pipe(
			filter((res) => res),
			withLatestFrom(this._store.pipe(select(currentAccesos))),
			map(([_, formularioAcciones]) => {
				console.log({ formularioAcciones });
				const access = formularioAcciones.filter(({ codigo }) => codigo === formCode);
				if (access.length > 0) return true;

				this._router.navigateByUrl('error/acceso-denegado');
				return false;
			})
		);
	}
}
